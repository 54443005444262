import React, { useState } from "react";
import '../styles/landingPage.css'
import {colors} from './colors';
import Button from './button';
import TestimonialCard from './testimonialCard'
import ContentCard from './contentCard'

const LandingPage = ({buttonClicked}) => {
    const headerColor = colors.strong;

    return (
        <div id='app-container'>
            <div id="main-content-box">
                <div id="main-content-box-text">
                    <h2 class="header-text">Your AI Email<br/>Marketing Assistant</h2>
                    <p class="body-text">Understand your audience niche.<br/>Generate curated content.<br/>Connect on a personal level.</p>
                    <Button name="Join Waitlist" className="button-small-green-blue" onClick={buttonClicked} accentClolor={colors.primary} />
                </div>
                <img src="/header-graphic.png" />
            </div>
            <div id="stats-box">
                <ContentCard 
                    headerText="107% ⬆️"
                    bodyText="Increase in audience retention, compared to undifferentiated content"
                    headerColor={colors.accent} />
                <ContentCard 
                    headerText="4 hours"
                    bodyText="Time saved per Email Campaign, compared to manually segmenting content"
                    headerColor={colors.accent} />
                <ContentCard 
                   headerText="130% ⬆️"
                   bodyText="Increase in click through rate from using segmented content"
                   headerColor={colors.accent} />
            </div>
            <div id="textimonial-box">
                <TestimonialCard 
                    imgSrc="/suja-profile.jpg"
                    testimonial="Greatly increased my audience engagement and freed up time for me to focus on my recipes"
                    person="Suja, PepperBowl" />
            </div>
            <div id="selling-point-box-all">
                <div class="selling-point-box">
                    <div class="selling-point-box-header">
                        <h2 class="header-text">Supercharge your Marketing ⚡️</h2>
                    </div>
                    <div class="selling-point-box-text">
                        <p class="body-text">TribeMails is your personal AI marketing assistant.  Send curated emails. See trends with engagement. Ask questions about your audience.  And much, much more...</p>
                    </div>
                </div>
                <div class="selling-point-box">
                    <div class="selling-point-box-header">
                    <h2 class="header-text">Why Segmentation?</h2>
                    </div>
                    <div class="selling-point-box-text">
                    <p class="body-text">Segmentation gives your audience the content they want, when they want it. Does half your audience enjoy one type of content while the other half enjoys something else? No problem. TribeMails handles this automatically!</p>
                    </div>
                </div>
            </div>
            <div id="how-it-works-box"></div>
            <div id="customers-box">
                <h2 class="header-text">Our Customers</h2>
                <img src="/pepperbowl-logo.jpg" />
            </div>
        </div>
    );
}

export default LandingPage;
