import React, { useState, useEffect, useRef } from 'react';
import Navbar from "./components/navbar";
import LandingPage from "./components/landingPage";
import Modal from './components/modal'
import './styles/app.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const canvasRef = useRef(null);

    return (
        <div id="app">
            <Navbar buttonClicked={openModal} />
            <Modal isOpen={isModalOpen} onClose={closeModal} />
            <LandingPage buttonClicked={openModal} />
        </div>
    );
}

export default App;
