import React, {useState} from 'react';
import '../styles/modal.css';

const Modal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
  });
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null); 

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    setSuccess(null);

    console.log(formData)
    try {
      const response = await fetch('https://api.tribemails.com/v1/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      setSuccess(true);
      if (response.ok) {
        console.log('Form submitted successfully!');
        setSuccess(true);
      }
      else {
        setSuccess(false);
        console.log(response);
        console.error('Error submitting form');
      }
    } catch (error) {
      setSuccess(false);
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div class='modal-container' onClick={(e) => e.stopPropagation()}>
        <button class='close-button' onClick={onClose}>×</button>
        <h2>Join our Waitlist</h2>
        <form onSubmit={handleSubmit}>
          <div class='form-field'>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div class='form-field'>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div class='form-field'>
            <label htmlFor="company">Company</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </div>
          <div class="submit-menu">
            <button class='submit-button' type="submit" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} disabled={loading}>Submit</button>
            {loading ? (
                <span className="spinner"></span>
              ) : success === true ? (
                <span className='form-success'>✔ Success</span>
              ) : success === false ? (
                <span className='form-error'>✖ Error</span>
              ) : (
                ''
              )
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
