import React, {useState} from 'react';
import '../styles/navbar.css';
import Button from './button';
import {colors} from './colors';

const Navbar = ({buttonClicked}) => {
    const handleSignUpClick = () => {
        console.log('Sign Up clicked');
    };

    return (
        <nav>
            <div id="nav-container">
                <img src="/tribemails-logo.png" />
                <Button name="Join Waitlist" onClick={buttonClicked} accentClolor={colors.primary} />
            </div>
         </nav>
    );
};

export default Navbar;
