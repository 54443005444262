import React, { useState } from 'react';

const ContentCard = ({headerText, bodyText, headerColor}) => {
    const [isHovered, setIsHovered] = useState(false);

    const containerStyle = {
        width: "300px",
        background: isHovered ? "#E5E5E5" : "#F5F5F5",
        borderRadius: "50px",
        padding: "30px",
        transition: '1s ease',
        margin: "10px"
    }
    const headerStyle = {
        color: headerColor,
        fontWeight: "bold"
    }
    const textStyle = {
        color: "#383838",
        lineHeight: "32px",
        fontSize: "20px",
        marginTop: "20px"
    }

    return (
        <div 
            style={containerStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <h1 style={headerStyle}>{headerText}</h1>
            <p style={textStyle}>{bodyText}</p>
        </div>
    )
}

export default ContentCard;
