import React from 'react';


const TestimonialCard = ({imgSrc, testimonial, person}) => {
    const containerStyle = {
        display: "flex",
        width: "500px"
    }
    const containerImageStyle = {
        width: "100px",
        height: "100px",
        borderRadius: "50px",
        marginTop: "25px"
    }
    const textContainerStyle = {
        marginLeft: "auto",
        width: "400px",
        paddingLeft: "10px"
    }
    const containerStarsStyle = {
        width: "125px",
    }
    const textimonialText = {
        color: "#383838"
    }
    const sourceText = {
        fontStyle: "italic",
        color: "484848"
    }

    return (
        <div style={containerStyle}>
            <img src={imgSrc} style={containerImageStyle} />
            <div style={textContainerStyle}>
                <img src="/stars.png" style={containerStarsStyle} />
                 <p style={textimonialText}>"{testimonial}"</p>
                 <p style={sourceText}>{person}</p>
            </div>
        </div>
    )
}

export default TestimonialCard;
