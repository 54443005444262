import React, { useState } from 'react';


const Button = ({ name, onClick, accentClolor }) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: isHovered ? accentClolor : '#fff',
        padding: '10px 15px',
        border: '2px solid',
        borderColor: accentClolor,
        borderRadius: '20px',
        color: isHovered ? '#fff' : accentClolor,
        transition: '0.5s ease',
        position: 'relative',
        cursor: 'pointer'
    }

    return (
        <button 
            style={buttonStyle}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {name}
        </button>
    );
};

export default Button;
